// extracted by mini-css-extract-plugin
export var CookiesNotice = "cookiesNotice-module--CookiesNotice--PrY9J";
export var CookiesNotice_acceptButton = "cookiesNotice-module--CookiesNotice_acceptButton--7HauD";
export var CookiesNotice_buttons = "cookiesNotice-module--CookiesNotice_buttons--rqNOX";
export var CookiesNotice_cookie = "cookiesNotice-module--CookiesNotice_cookie--N3J0T";
export var CookiesNotice_cookieSave = "cookiesNotice-module--CookiesNotice_cookieSave--FWyXN";
export var CookiesNotice_cookieTitle = "cookiesNotice-module--CookiesNotice_cookieTitle--VO4NJ";
export var CookiesNotice_declineButton = "cookiesNotice-module--CookiesNotice_declineButton--qSok7";
export var CookiesNotice_description = "cookiesNotice-module--CookiesNotice_description--Y4-yC";
export var CookiesNotice_personalizeButton = "cookiesNotice-module--CookiesNotice_personalizeButton--S526Y";
export var CookiesNotice_title = "cookiesNotice-module--CookiesNotice_title--8Kbmr";
export var CookiesNotice_wrapper = "cookiesNotice-module--CookiesNotice_wrapper--cDLBK";