exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-diverger-jsx": () => import("./../../../src/pages/about-diverger.jsx" /* webpackChunkName: "component---src-pages-about-diverger-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-success-jsx": () => import("./../../../src/pages/contact-success.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-us-jsx": () => import("./../../../src/pages/join-us.jsx" /* webpackChunkName: "component---src-pages-join-us-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-methodology-jsx": () => import("./../../../src/pages/methodology.jsx" /* webpackChunkName: "component---src-pages-methodology-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-sgsi-policy-jsx": () => import("./../../../src/pages/sgsi-policy.jsx" /* webpackChunkName: "component---src-pages-sgsi-policy-jsx" */),
  "component---src-pages-training-program-jsx": () => import("./../../../src/pages/training-program.jsx" /* webpackChunkName: "component---src-pages-training-program-jsx" */)
}

