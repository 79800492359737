import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as style from "./footer.module.scss";
import HiddenLinkIfCurrentPage from "../HiddenLinkIfCurrentPage";

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <footer className={style.Footer}>
      <p>
        <span>{`© ${currentYear} Trileuco Solutions`}</span>
        <HiddenLinkIfCurrentPage
          linkTo="privacy-policy"
          linkText={t("nav.privacyPolicy")}
        />
        <HiddenLinkIfCurrentPage linkTo="legal" linkText={t("nav.legal")} />
        <HiddenLinkIfCurrentPage
          linkTo="sgsi-policy"
          linkText={t("nav.securityPolicy")}
        />

        <HiddenLinkIfCurrentPage
          linkTo="cookies-policy"
          linkText={t("nav.cookiesPolicy")}
        />
      </p>
    </footer>
  );
};

export default Footer;
