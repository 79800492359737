import * as React from "react";
import { Cursor } from "react-creative-cursor";
import { DesktopView } from "../Mediaqueries";

const CursorProvider = () => {
  return (
    <>
      <DesktopView>
        <Cursor
          isGelly={false}
          cursorSize="1px"
          cursorBackgrounColor="#ffffff20"
          animationDuration={0.4}
        />
      </DesktopView>
    </>
  );
};

export default CursorProvider;
