import * as React from "react";

const MutableCursorArea = ({
  Tag,
  children,
  cursorSize,
  cursorColor,
  ...otherProps
}) => {
  return (
    <Tag data-cursor-size="48px" data-cursor-color="#ffffff20" {...otherProps}>
      {children}
    </Tag>
  );
};

export default MutableCursorArea;
