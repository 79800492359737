import * as React from "react";
import * as style from "./morphingScrollImg.module.scss";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { GatsbyImage } from "gatsby-plugin-image";

const frontImgVariants = {
  initial: {
    opacity: 0.5,
    transition: { delay: 0, duration: 1.5 },
  },
  inView: {
    opacity: 0,
    transition: { delay: 0, duration: 1.5 },
  },
};

const MotionGatsbyImg = React.forwardRef((props, ref) => (
  <GatsbyImage innerRef={ref} {...props} />
));

const MotionGatsbyImage = motion(MotionGatsbyImg, { forwardMotionProps: true });

const MorphingScrollImg = ({
  className,
  img,
  alt = "",
  children,
  threshold = 0.8,
  ...other
}) => {
  const { ref, inView } = useInView({
    threshold: threshold,
  });
  return (
    <motion.div
      className={`${style.MorphingScrollImg} ${
        className ? className : ""
      }`.trim()}
      ref={ref}
      {...other}
    >
      <div className={style.MorphingScrollImg_back}>
        <GatsbyImage image={img} alt={alt} />
      </div>
      <motion.div
        className={style.MorphingScrollImg_front}
        variants={frontImgVariants}
        initial="initial"
        animate={inView ? "inView" : "initial"}
      >
        <MotionGatsbyImage image={img} alt={alt} />
      </motion.div>
      {children}
    </motion.div>
  );
};

export default MorphingScrollImg;
