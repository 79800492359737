import * as React from "react";
import { useCycle } from "framer-motion";
import NavBar from "./NavBar";
import NavModal from "./NavModal";

const Nav = () => {
  const [open, cycleOpen] = useCycle(false, true);
  return (
    <>
      <NavModal open={open} cycleOpen={cycleOpen} />
      <NavBar open={open} cycleOpen={cycleOpen} />
    </>
  );
};

export default Nav;
