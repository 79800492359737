import * as React from "react";
import "../styles/globals.scss";
import "./layout.module.scss";
import "react-creative-cursor/dist/styles.css";
import Nav from "components/Nav";
import Footer from "components/Footer";
import CookiesNotice from "components/CookiesNotice";

const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      {children}
      <CookiesNotice />
      <Footer />
    </>
  );
};

export default Layout;
