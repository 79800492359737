import * as React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import * as style from "./languageSelector.module.scss";
import MutableCursorArea from "../MutableCursorArea";

const LanguageSelector = ({ cycleOpen }) => {
  const { languages, changeLanguage } = useI18next();
  return (
    <ul className={style.LanguageSelector}>
      {languages.map((lng) => (
        <li key={lng}>
          <MutableCursorArea
            Tag="button"
            onClick={(e) => {
              e.preventDefault();
              changeLanguage(lng);
            }}
          >
            {lng}
          </MutableCursorArea>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSelector;
