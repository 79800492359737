import * as React from "react";
import * as style from "./cookiesNotice.module.scss";
import { CookieNotice } from "gatsby-cookie-notice";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import CookieTwoToneIcon from "@mui/icons-material/CookieTwoTone";

const CookiesNotice = () => {
  const { t } = useTranslation();
  return (
    <CookieNotice
      acceptButtonText={t("cookies.acceptButton")}
      declineButtonText={t("cookies.declineButton")}
      backgroundWrapperClasses={style.CookiesNotice}
      backgroundClasses={style.CookiesNotice_wrapper}
      wrapperClasses={style.CookiesNotice_buttons}
      acceptButtonClasses={style.CookiesNotice_acceptButton}
      declineButtonClasses={style.CookiesNotice_declineButton}
      personalizeButtonClasses={style.CookiesNotice_personalizeButton}
      cookieMapClasses={style.CookiesNotice_cookie}
      cookieTitleClasses={style.CookiesNotice_cookieTitle}
      personalizeValidationClasses={style.CookiesNotice_cookieSave}
      personalizeButtonText={t("cookies.preferencesButton")}
      personalizeValidationText={t("cookies.saveButton")}
      cookies={[
        {
          name: "necessary",
          editable: false,
          default: true,
          title: t("cookies.esential.title"),
          text: t("cookies.esential.description"),
        },
        {
          name: "gatsby-gdpr-google-analytics",
          editable: true,
          default: true,
          title: t("cookies.googleAnalytics.title"),
          text: t("cookies.googleAnalytics.description"),
        },
      ]}
    >
      <p className={style.CookiesNotice_title}>
        <CookieTwoToneIcon /> {t("cookies.title")}
      </p>
      <p className={style.CookiesNotice_description}>
        {t("cookies.description")}{" "}
        <Link to="/cookies-policy">{t("cookies.info")}</Link>
      </p>
    </CookieNotice>
  );
};

export default CookiesNotice;
