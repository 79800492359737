import * as React from "react";
import * as style from "./nav.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import { useDisableBodyScroll } from "utils/useDisableBodyScroll";
import MutableCursorArea from "../MutableCursorArea";
import Logo from "images/DVG_trileuco.svg";
import { NotMobileView } from "../Mediaqueries";
import HiddenLinkIfCurrentPage from "../HiddenLinkIfCurrentPage";

const NavBarLink = ({ open, t }) => (
  <NotMobileView>
    <HiddenLinkIfCurrentPage
      linkTo="about-diverger"
      linkText={t("nav.aboutDiverger")}
      className={`${style.NavBarLink} ${
        open ? style.NavBarLink___hidden : ""
      }`.trim()}
    />
  </NotMobileView>
);

const NavMenuBtn = ({ open, cycleOpen }) => {
  return (
    <MutableCursorArea
      Tag="div"
      className={style.NavMenuBtn}
      onClick={cycleOpen}
      aria-hidden="true"
    >
      <input
        className={style.NavMenuBtn_checkbox}
        type="checkbox"
        checked={open}
        readOnly
      />
      <div>
        <span />
        <span />
      </div>
    </MutableCursorArea>
  );
};

const NavBar = ({ open, cycleOpen }) => {
  const { t } = useTranslation();
  useDisableBodyScroll(open);
  return (
    <div className={style.NavBar}>
      <MutableCursorArea
        Tag={Link}
        to="/"
        open={open}
        onClick={open && cycleOpen}
      >
        <img src={Logo} className={style.NavBar_logo} alt="Trileuco" />
      </MutableCursorArea>
      <NavBarLink open={open} t={t} />
      <NavMenuBtn open={open} cycleOpen={cycleOpen} />
    </div>
  );
};

export default NavBar;
