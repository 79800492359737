// extracted by mini-css-extract-plugin
export var MiniContact = "nav-module--MiniContact--fgN3l";
export var NavBar = "nav-module--NavBar--wIbyR";
export var NavBarLink = "nav-module--NavBarLink--5PQXz";
export var NavBarLink___hidden = "nav-module--NavBarLink___hidden--7J9Qp";
export var NavBar_logo = "nav-module--NavBar_logo--VbfKm";
export var NavLink = "nav-module--NavLink--vwisw";
export var NavLink_circle = "nav-module--NavLink_circle--pJ5sR";
export var NavLink_img = "nav-module--NavLink_img--L1gTa";
export var NavLink_name = "nav-module--NavLink_name--uZmpK";
export var NavLinks = "nav-module--NavLinks--lM9yu";
export var NavMenuBtn = "nav-module--NavMenuBtn--SD2nQ";
export var NavMenuBtn_checkbox = "nav-module--NavMenuBtn_checkbox--UY6F8";
export var NavModal = "nav-module--NavModal--0lHAw";
export var NavModal_footer = "nav-module--NavModal_footer--OwIUY";
export var NavModal_main = "nav-module--NavModal_main--2iGY-";
export var SocialLink = "nav-module--SocialLink--owOZC";
export var SocialLinks = "nav-module--SocialLinks--HsjUX";
export var l = "nav-module--l--Jxw5n";
export var m = "nav-module--m--FDUaP";
export var s = "nav-module--s--MuY0P";
export var xs = "nav-module--xs--E-1fK";