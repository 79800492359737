import * as React from "react";
import * as style from "./nav.module.scss";
import { StaticQuery, graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import MutableCursorArea from "../MutableCursorArea";
import LanguageSelector from "../LanguageSelector";
import { useMediaQuery } from "react-responsive";
import { notDesktopBreakpoint } from "../Mediaqueries";
import CursorProvider from "../CursorProvider";
import MorphingScrollImg from "../MorphingScrollImg/";
import { getImage } from "gatsby-plugin-image";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";

const modalVariants = {
  closed: {
    opacity: 0,
    transition: { delay: 0.5, duration: 0.5 },
  },
  open: { opacity: 1 },
};

const navLinkVariants = {
  closed: { opacity: 0 },
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const navLinkNameVariants = {
  closed: {
    scale: 0,
  },
  open: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  hover: {
    boxShadow: "0px 0px 0px 12px #263546",
    transition: {
      delay: 0.1,
      duration: 0.5,
      type: "spring",
    },
  },
};

const navLinksData = [
  {
    title: "nav.services",
    link: "/services",
    img: (data) => data && getImage(data.servicesImg),
    size: style.l,
  },
  {
    title: "nav.about",
    link: "/about",
    img: (data) => data && getImage(data.aboutImg),
    size: style.m,
  },
  {
    title: "nav.contact",
    link: "/contact",
    img: (data) => data && getImage(data.contactImg),
    size: style.m,
  },
  {
    title: "nav.methodology",
    link: "/methodology",
    img: (data) => data && getImage(data.methodologyImg),
    size: style.m,
  },
  {
    title: "nav.joinUs",
    link: "/join-us",
    img: (data) => data && getImage(data.joinUsImg),
    size: style.l,
  },
  {
    title: "nav.aboutDiverger",
    link: "/about-diverger",
    img: (data) => data && getImage(data.aboutDivergerImg),
    size: style.m,
  },
];

const MotionLink = motion(Link, { forwardMotionProps: true });

const NavLink = ({
  title,
  link,
  img,
  size,
  parallaxDistance,
  cycleOpen,
  onClick,
}) => {
  return (
    <MotionLink
      to={link}
      onClick={onClick}
      variants={navLinkVariants}
      initial="closed"
      animate="open"
      exit="closed"
      whileHover="hover"
      className={`${style.NavLink} ${size}`}
    >
      <div className={style.NavLink_circle}>
        <MorphingScrollImg img={img} className={style.NavLink_img} />
      </div>
      <motion.span
        className={style.NavLink_name}
        variants={navLinkNameVariants}
      >
        {title}
      </motion.span>
    </MotionLink>
  );
};

const SocialLink = ({ link, icon, size, parallaxDistance }) => (
  <motion.a
    className={style.SocialLink}
    href={link}
    variants={navLinkNameVariants}
    initial="closed"
    animate="open"
    exit="closed"
    whileHover="hover"
  >
    {icon}
  </motion.a>
);

const NavLinks = ({ cycleOpen, data, t }) => {
  const isNotDesktop = useMediaQuery(notDesktopBreakpoint);
  return (
    <StaticQuery
      query={graphql`
        query {
          servicesImg: file(relativePath: { eq: "nav/services.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          aboutImg: file(relativePath: { eq: "nav/about.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          contactImg: file(relativePath: { eq: "nav/contact.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          joinUsImg: file(relativePath: { eq: "nav/joinus.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          methodologyImg: file(relativePath: { eq: "nav/methodology.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          aboutDivergerImg: file(relativePath: { eq: "misc/growth.jpg" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      `}
      render={(data) => (
        <motion.div className={style.NavLinks}>
          {navLinksData.map(
            ({ title, img, link, size, parallaxDistance, onClick }, i) => (
              <NavLink
                key={i}
                title={t(title)}
                img={img(data)}
                link={link}
                size={size}
                parallaxDistance={parallaxDistance}
                onClick={cycleOpen}
              />
            )
          )}
          <div className={style.SocialLinks}>
            <SocialLink
              icon={<LinkedInIcon />}
              link="https://www.linkedin.com/company/trileuco-solutions/?originalSubdomain=es"
              parallaxDistance={isNotDesktop ? "-0.02" : "-0.2"}
            />
            <SocialLink
              icon={<XIcon />}
              link="https://twitter.com/trileuco?lang=es"
              parallaxDistance={isNotDesktop ? "-0.02" : "0.2"}
            />
          </div>
        </motion.div>
      )}
    />
  );
};

const MiniContact = () => (
  <div className={style.MiniContact}>
    <MutableCursorArea Tag="a" href="">
      +34 881 893 736
    </MutableCursorArea>
    <MutableCursorArea Tag="a" href="">
      info@trileucosolutions.com
    </MutableCursorArea>
  </div>
);

const NavModal = ({ open, cycleOpen }) => {
  const { t } = useTranslation();
  return (
    <>
      {open && <CursorProvider />}
      <AnimatePresence>
        {open && (
          <motion.div
            className={style.NavModal}
            variants={modalVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <div className={style.NavModal_main}>
              <NavLinks cycleOpen={cycleOpen} t={t} />
            </div>
            <div className={style.NavModal_footer}>
              <LanguageSelector />
              <MiniContact />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavModal;
