import * as React from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import MutableCursorArea from "../MutableCursorArea";

const HiddenLinkIfCurrentPage = ({ linkTo, linkText, ...other }) => {
  const location = useLocation();
  return (
    <>
      {location.pathname.indexOf(linkTo) > -1 ? null : (
        <MutableCursorArea Tag={Link} to={`/${linkTo}`} {...other}>
          {linkText}
        </MutableCursorArea>
      )}
    </>
  );
};

export default HiddenLinkIfCurrentPage;
